// overrides the adyen.scss

.adyen-checkout__input-wrapper {
  width: 100%;
}

.adyen-checkout__input {
  border-radius: 10px;
  border: none;
  width: 100%;
  padding-left: 16px;
  transition: box-shadow 0.2s ease-in-out 0s, background 0.2s ease-in-out 0s;
  border: none;
  background-color: rgba(0, 0, 0, 0.06);
  font-size: 14px;
  color: rgb(0, 0, 0);
  line-height: 24px;
  padding: 8px 16px;
  width: 100%;
  box-shadow: none;
  &::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &:focus,
  &:active,
  .adyen-checkout__input--focus, {
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 2px;
    border: none;
    outline: none;
  }
}

.adyen-checkout__input--focus {
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 2px;
  border: none;
  outline: none;
}

.adyen-checkout__input--error {
  box-shadow: rgb(234, 50, 35) 0px 0px 0px 2px;
  border: none;
}

// CC HOLDER NAME

// CC ROW

.adyen-checkout__field {
  height: 64px;
}

// CC INPUT FIELD
.adyen-checkout__card__cardNumber__input {
  padding: 5px 8px 5px 64px;
  width: 100%;
}

// CC IMG INSIDE INPUT FIELD
.adyen-checkout__card__cardNumber__input > img {
  box-shadow: #ccc 0px 0px 0px 1px;
  margin-left: 16px;
}

// EXPIRY AND CVC ROW

.adyen-checkout__card__exp-cvc {
  display: flex;
  justify-content: space-between;
}

// EXP INPUT

.adyen-checkout__field--expiryDate {
  width: 48%;
}

.adyen-checkout__card__exp-date__input {
  width: 100%;
}

// CVC FIELD

.adyen-checkout__card__exp-cvc .adyen-checkout__field {
  margin-right: 0;
}

.adyen-checkout__field--securityCode {
  width: 48%;
}

.adyen-checkout__card__cvc__input {
  width: 100%;
}

// ERRORMESSAGE

.adyen-checkout__error-text {
  color: #ea3223;
  font-family: 'Sailec Bold', sans-serif;
}

// LABEL

.adyen-checkout__label__text {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Sailec Bold', sans-serif;
  padding-bottom: 4px;
}

.adyen-checkout__label--focused .adyen-checkout__label__text {
  color: rgba(0, 0, 0, 0.6);
}
